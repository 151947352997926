<template lang="pug">
settings-panel
  template(#header) Пароль
  .change-password__paragraph
    | Для изменения текущего пароля введите новый в оба поля.
    br
    br
    | Для более надежного пароля:
    br

    ol
      li Используйте не менее 8 символов;
      li Используйте цифры;
      li Используйте строчные буквы;
      li Используйте заглавные буквы;
      li Используйте символы.
  form.change-password
    .change-password__input
      validation-row(
        ref="refCurrentPassword"
        required
        not-empty
        autocomplete="current-password"
        :watch-validation="watchValidation"
        :model-value="form.currentPassword"
        label="Текущий пароль"
        @error="errorChecker('password_current')"
        @correct="correctChecker('password_current')"
      )
        password-input(v-model="form.currentPassword")
    .change-password__input
      validation-row(
        ref="refPasswordFirst"
        required
        not-empty
        autocomplete="new-password"
        :watch-validation="watchValidation"
        :model-value="form.passwordFirst"
        label="Новый пароль"
        @error="errorChecker('password_first')"
        @correct="correctChecker('password_first')"
      )
        password-input(v-model="form.passwordFirst")
      validation-row(
        ref="refPasswordSecond"
        required
        custom
        not-empty
        autocomplete="new-password"
        label="Повторите новый пароль"
        :watch-validation="watchValidation"
        :model-value="form.passwordSecond"
        :custom-checker="customValidator"
        @error="errorChecker('password_second')"
        @correct="correctChecker('password_second')"
      )
        password-input(v-model="form.passwordSecond")
  .reliability(v-if="security !== 1")
    | Надежность пароля:
    |
    span(:class="security === 2 ? 'weak' : security === 3 ? 'medium' : 'strong'") {{ securityLabel }}
  .error(v-if="errorMessage") {{ errorMessage }}
  template(#actions)
    ui-button(style="width: fit-content" @click="validate" :loader="loader") Сохранить
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, watch } from "vue";

import { useForm } from "@/use/other/useForm";
import { checkPasswordStrength } from "@/utils/checkers/checkers";
import { useApi } from "@/use/api/useApi";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { getErrorMessage } from "@/use/users/useUserModifier";

import UiButton from "@/components/ui/button/UiButton.vue";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import PasswordInput from "@/components/ui/form/input/PasswordInput.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";

export default defineComponent({
  name: "PasswordPanel",
  components: {
    UiButton,
    SettingsPanel,
    PasswordInput,
    ValidationRow,
  },
  setup() {

    const form = reactive({
      currentPassword: "",
      passwordFirst: "",
      passwordSecond: "",
    });

    const watchValidation = ref(true);

    const { me } = useUserAccess();

    const refCurrentPassword = ref();
    const refPasswordFirst = ref();
    const refPasswordSecond = ref();

    const loader = ref(false);
    const errorMessage = ref('');

    const { errorChecker, correctChecker, errList } = useForm(() => ({}));

    const security = computed(() => checkPasswordStrength(form.passwordFirst))

    const securityLabel = computed(() => {
      switch(security.value) {
        case 2: return 'Слабый';
        case 3: return 'Средний';
        case 4: return 'Надежный';
        default: return '';
      }
    })

    function validate() {
      refCurrentPassword.value.validate()
      refPasswordFirst.value.validate()
      refPasswordSecond.value.validate()
      savePassword()
    }

    function changePassword(payload: any) {
      loader.value = true;
      errorMessage.value = '';

      useApi().security.patchPassword(me.value.id, payload)
        .then(() => {
          watchValidation.value = false
          form.currentPassword = ''
          form.passwordFirst = ''
          form.passwordSecond = ''

          setTimeout(() => watchValidation.value = true, 500)
          useNotificationsStore().showSuccess('Пароль успешно обновлен')
        })
        .catch((error) => errorMessage.value = getErrorMessage(error?.response?.status, error?.data))
        .finally(() => loader.value = false)
    }

    function savePassword() {
      if (errList.value.length === 0 && form.passwordFirst === form.passwordSecond) {
        changePassword({
          currentPassword: form.currentPassword,
          newPassword: form.passwordFirst,
        })
      }
    }

    function customValidator(pass: string) {
      return pass === form.passwordFirst ? '' : 'Пароли не совпадают'
    }

    watch(() => form.passwordFirst, () => {
      if (form.passwordSecond !== '') {
        refPasswordSecond.value.validate()
      }
    })

    return {
      form,
      refCurrentPassword,
      refPasswordFirst,
      refPasswordSecond,
      security,
      securityLabel,
      watchValidation,
      loader,
      errorMessage,
      errorChecker,
      correctChecker,
      validate,
      customValidator,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.change-password {
  display: flex;
  flex-flow: column;
  gap: 16px;
  width: 100%;
}

.change-password__paragraph {
  @include label-14-17();
}

.change-password__input {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
}

.error {
  color: var(--main-red-color);
  font-size: 12px;
  line-height: 16px;
}

.reliability {
  @include label-12-16();

  .weak {
    color: var(--main-red-color);
  }

  .medium {
    color: var(--main-warning-color);
  }

  .strong {
    color: var(--main-color-green);
  }
}

@media (max-width: 760px) {
  .change-password {
    gap: 8px;
  }
  .change-password__input {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }
}
</style>
