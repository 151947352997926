<template lang="pug">
settings-panel
  template(#header) Мой профиль

  .settings-panel__card-inputs
    validation-row(
      watch-validation
      not-empty
      v-model="form.surName"
      label="Фамилия"
      name="surname"
      placeholder="Введите фамилию"
      @error="errorChecker('form_surname')"
      @correct="correctChecker('form_surname')"
    )
    validation-row(
      watch-validation
      not-empty
      v-model="form.firstName"
      label="Имя"
      placeholder="Введите имя"
      name="firstname"
      @error="errorChecker('form_first_name')"
      @correct="correctChecker('form_first_name')"
    )
    form-input(
      watch-validation
      v-model="form.middleName"
      label="Отчество"
      placeholder="Введите отчество"
    )
    timezone-selector(v-model="form.timezone" @error="errorChecker" @correct="correctChecker")
    validation-row(
      watch-validation
      not-empty
      type-phone
      v-model="form.phone"
      label="Телефон"
      placeholder="Введите телефон"
      @error="errorChecker('form_phone')"
      @correct="correctChecker('form_phone')"
    )
  template(v-if="errorMessage" v-slot:comments)
    span Возникла ошибка при сохранении данных:
    span.error-message {{ errorMessage }}

  template(#actions)
    ui-button(:loader="loader" @click="validateForm") Сохранить

pop-confirm(v-model:show="showPopConfirm" @event="popConfirmEvent")
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, ref, watch } from 'vue'
import { useForm } from "@/use/other/useForm";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useHasChanges } from "@/use/other/useHasChanges";
import { onBeforeRouteLeave } from "vue-router";
import { useUserModifier } from "@/use/users/useUserModifier";

import UiButton from "@/components/ui/button/UiButton.vue";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import TimezoneSelector from "@/components/selector/TimezoneSelector.vue";
import FormInput from "@/components/ui/form/input/FormInput.vue";
import { Tabs } from "@/router/tabs";

export default defineComponent({
  name: "ProfilePanel",
  components: {
    UiButton,
    SettingsPanel,
    FormInput,
    ValidationRow,
    TimezoneSelector,
    PopConfirm: defineAsyncComponent(() => import("@/components/other/PopConfirm.vue")),
  },
  setup() {

    const {
      me,
    } = useUserAccess();

    const loader = ref(false);
    const errorMessage = ref('');

    const {
      form,
      saveForm,
    } = useUserModifier(false, false, me.value)

    function saveChanges() {
      loader.value = true;
      errorMessage.value = '';

      saveForm()
        .then(() => updateCopy())
        .catch((error) => errorMessage.value = error)
        .finally(() => loader.value = false);
    }

    const { validateForm, errorChecker, correctChecker } = useForm(saveChanges);

    const {
      showPopConfirm,
      popConfirmEvent,
      checkChanges,
      updateCopy,
    } = useHasChanges(form);

    onBeforeRouteLeave((to, from, next) => {
      if (to.name !== Tabs.Landing) checkChanges(next)
      else next()
    })

    return {
      Tabs,
      loader,
      form,
      showPopConfirm,
      errorMessage,
      validateForm,
      errorChecker,
      correctChecker,
      popConfirmEvent,
    };
  }
})
</script>

<style scoped lang="scss">
.error-message {
  color: var(--main-red-color);
}

.settings-panel__card-inputs {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 760px) {
  .settings-panel__card-inputs {
    grid-gap: 8px;
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
