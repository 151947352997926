<template lang="pug">
settings-page(title="Мой профиль")
  profile-panel
  email-panel
  password-panel
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SettingsPage from "@/components/pages/settings/common/SettingsPage.vue";
import PasswordPanel from "@/components/pages/settings/accountPage/PasswordPanel.vue";
import ProfilePanel from "@/components/pages/settings/accountPage/ProfilePanel.vue";
import EmailPanel from "@/components/pages/settings/accountPage/EmailPanel.vue";

export default defineComponent({
  name: "MyAccount",
  components: {
    EmailPanel,
    SettingsPage,
    ProfilePanel,
    PasswordPanel,
  },
});
</script>

<style scoped lang="scss">
</style>
